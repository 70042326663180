/*

common 
font
*/

// @import url(http://fonts.googleapis.com/earlyaccess/notosanskr.css); font-family: 'Noto Sans KR', sans-serif;

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  // src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format('woff2'),
  // url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format('woff'),
  src: url(./font/NotoSansKR-Thin.otf) format("opentype");
  //url(./font/NotoSansKR-Thin.woff) format('woff');
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  // src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format('woff2'),
  // url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format('woff'),
  src: url(./font/NotoSansKR-Light.otf) format("opentype");
  //url(./font/NotoSansKR-Light.woff) format('woff');
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  // src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format('woff2'),
  // url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format('woff'),
  src: url(./font/NotoSansKR-Regular.otf) format("opentype");
  //url(./font/NotoSansKR-Regular.woff) format('woff');
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  // src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format('woff2'),
  // url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format('woff'),
  src: url(./font/NotoSansKR-Medium.otf) format("opentype");
  //url(./font/NotoSansKR-Medium.woff) format('woff');
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  // src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format('woff2'),
  // url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format('woff'),
  src: url(./font/NotoSansKR-Bold.otf) format("opentype");
  //url(./font/NotoSansKR-Bold.woff) format('woff');
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  // src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format('woff2'),
  // url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format('woff'),
  src: url(./font/NotoSansKR-Black.otf) format("opentype");
  //url(./font/NotoSansKR-Black.woff) format('woff');
}

/* reset */

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
th,
td,
table,
pre,
code,
select,
form,
fieldset,
legend,
input,
address,
ol,
ul,
li,
dl,
dt,
dd,
img,
blockquote {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-family: "Noto Sans KR", sans-serif;
}

body,
input,
button,
select,
textarea {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  line-height: 1.4;
  color: #333;
  font-weight: normal;
}
table {
  table-layout: fixed;
  width: 100%;
  border: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border: 0;
}

ol {
  list-style-type: decimal;
  text-indent: 10px;
  margin-left: 0px;
}
ol li {
  margin-bottom: 10px !important;
}
img,
fieldset {
  border: 0;
  vertical-align: middle;
}

button {
  overflow: visible;
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

textarea {
  padding: 10px;
  font-size: 12px;
  color: #333;
  border: 1px solid #ccc;
  vertical-align: top;
  resize: none;
  outline: none;
}

select {
  padding: 0 5px;
  font-size: 12px;
  color: #333;
  vertical-align: top;
  resize: none;
  outline: none;
  height: 30px;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="text"],
input[type="password"] {
  height: 28px;
  padding: 0;
  font-size: 12px;
  background: none;
  color: #333;
}

input[type="text"]:disabled,
input[type="button"]:disabled,
input[type="password"]:disabled {
  color: #999;
}

input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #aaa;
}

caption,
hr {
  display: none;
}

em,
i,
address {
  font-style: normal;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  cursor: pointer;
}

/* layout #2e5281 */
button:focus,
div:focus {
  outline: 0;
}

::-ms-clear,
::-ms-reveal {
  display: none;
}

#root {
  max-height: calc(100% - 41px);
  margin: 0 auto;
  // overflow-y: scroll;
}

.wrap {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  max-height: calc(100% - 3px);
  position: relative;
  padding-bottom: 150px;
  box-sizing: border-box;
}

.main {
  width: 100%;
  text-align: center;

  border-bottom: solid 1px #dedede;
}

.header {
  height: 68px;
  background-color: #fff;
  display: inline-flex;
  position: relative;
  width: 1420px;
}

.top-line {
  height: 6px;
  display: flex;
  width: 100%;
  background-color: #076db6;
}

.header .logo {
  width: 200px;
  margin-top: 5px;
  margin-right: 0px;
}

.header .menu-text {
  font-size: 17px;
  font-weight: 600;
  padding-right: 0px;
  width: 1500px;
}

.header .menu-text li {
  display: inline-block;
  text-align: center;
  width: 155px;
  margin-left: 2px;
  height: 68px;
  color: #545454;
  line-height: 67px;
  cursor: pointer;
  outline: inherit;
}

.header .menu-text .infoLi {
  display: inline-block;
  text-align: center;
  width: 240px;
  margin-left: 0px;
  height: 68px;
  color: #545454;
  line-height: 67px;
  cursor: pointer;
}

.header .menu-text li:hover {
  background-color: #d7e9ff;
}

.header .menu-text .current-li {
  display: inline-block;
  text-align: center;
  width: 155px;
  margin-left: 2px;
  height: 68px;
  color: #545454;
  background-color: #d7e9ff;
  border-bottom: 1px solid #ccc;
}

.header .menu-text .sel-line {
  display: none;
  width: 155px;
  top: 0px;
  position: absolute;
  background-color: #2e5281;
}

.header .menu-text .current-line {
  display: block;
  width: 155px;
  top: -6px;
  height: 6px;
  position: absolute;
  background-color: #3769aa;
}

.header .menu-text .select-li {
  background-color: #d7e9ff;
  outline: inherit;
}

.header .menu-text .current-ul {
  display: none;
}

.header .top-area {
  float: right;
  width: 245px;
  margin: 10px 0 0 0;
  text-align: right;
}

.header .top-user {
  font-size: 12px;
  color: #1579b9;
  font-weight: 500;
  text-align: right;
  margin: 0 0 10px 0;
  padding: 0 5px 0 0;
  height: 15px;
  max-width: 169px;
}
.header .top-logout {
  width: 100%;
}
.header .top-area a {
  margin-top: 10px;
}

.ul .li .ul {
  display: none;
  opacity: 0;
}

.ul .li:hover > .sel-line {
  display: block;
}

.ul .li:hover > .ul {
  display: block;
  opacity: 1;
  position: absolute;
  top: 67px;
  color: #fff;
  width: 140px;
  text-align: left;
  padding: 20px 0px 30px 15px;
  background-color: #2e5281;
  z-index: 9999;
}

.ul .ul .li {
  color: #fff;
  font-size: 13px;
  font-weight: 300;
  margin: 0;
  line-height: 32px;
  display: block;
}

.ul .ul .li:hover {
  color: #b8e9ff;
}

.ul .ul .li:hover > .ul {
  transform: translateX(0) translateY(0);
}

.bigtitle {
  height: 50px;
  background-color: #f2f2f2;
  border-bottom: solid 1px #dedede;
  display: inline-flex;
  width: 1420px;
}

.bigtitle .select_menu {
  width: 201px;
  height: 50px;
  background-color: #2e5281;
  text-align: center;
}

.bigtitle .select_menu {
  font-size: 18px;
  font-weight: 400;
  line-height: 45px;
  color: #ffffff;
}

.bigtitle .title_main {
  font-size: 22px;
  font-weight: 500;
  color: #2e5281;
  line-height: 47px;
  padding-left: 40px;
  width: 550px;
  text-align: left;
}

.bigtitle .location {
  width: 599px;
  padding-right: 10px;
  padding-top: 22px;
  text-align: right;
}
.MuiBreadcrumbs-ol {
  display: block;
  float: right;
}
.bigtitle .location .lotext {
  font-size: 12px;
  font-weight: 500;
  color: #6d6e71;
}

.container {
  display: inline-flex;
  position: relative;
  width: 1420px;
  margin-bottom: 3px;
}

.container .nav {
  position: absolute;
  left: 0;
  width: 201px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #dedede;
}

.container .MuiList-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}

.container .nav .MuiListItem-button {
  border-right: #dedede 1px solid;
  border-left: #dedede 1px solid;
  border-bottom: #f0f0f0 1px solid;
  height: 60px;
  margin-top: 0px;
}

.container .nav .MuiListItem-button:hover {
  text-decoration: none;
  background-color: #ebebeb;
}

.sub-title .MuiTypography-body1,
.root .MuiTypography-body1,
.paper .MuiTypography-body1 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: #545454;
}

.ssub-title a {
  font-size: 13px;
  color: #2e5281;
}

.container .nav .ssub-title .MuiListItem-button {
  height: 40px;
  border: 0px;
  border-right: #dedede 1px solid;
  border-left: #dedede 1px solid;
}

.container .nav .ssub-title .MuiListItem-button:hover {
  text-decoration: none;
  background-color: #ecf5ff;
  border-right: #dedede 1px solid;
  border-left: #dedede 1px solid;
}

.ssub-title .MuiTypography-body1 {
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #545454;
}

.ssub-title .MuiListItem-root {
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  justify-content: flex-start;
  text-decoration: none;
}

.MuiCollapse-container {
  border-bottom: 1px solid #f0f0f0;
  background-color: #f9fcff;
  height: 0;
  overflow: hidden;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.container .root {
  margin-left: 240px;
  margin-top: 30px;
}

.container .root h2,
.paper h2 {
  color: #2e5281;
  font-weight: 600;
  line-height: 21px;
  margin: 17px 0 17px 0;
  font-size: 20px;
  display: block;
  float: left;
  width: 100%;
  text-align: left;
}

.container .root h3,
.paper h3,
h3 {
  background-repeat: no-repeat;
  color: #2e5281;
  font-weight: 500;
  line-height: 20px;
  padding: 0 0 0 20px;
  margin: 0 0 20px 0;
  font-size: 18px;
  background-image: url(./images/h3.png);
  display: block;
  float: left;
  text-align: left;
  min-width: 80%;
}

.container .root h4,
.paper h4 {
  color: #2e5281;
  font-weight: 600;
  line-height: 15px;
  margin: 30px 0 10px 0;
  font-size: 15px;
  display: block;
  float: left;
  width: 100%;
  text-align: left;
}

.paper h5 {
  color: #2e5281;
  font-weight: 600;
  line-height: 15px;
  margin: 0px 0 5px 0;
  font-size: 15px;
  display: block;
  float: left;
  width: calc(100% - 23px);
  text-align: left;
}

.container .root .inforbox,
.paper .inforbox {
  height: auto;
  margin: 0 0 20px 10px;
  font-size: 14px;
  font-weight: 400;
  color: #555555;
  text-align: left;
  display: inline-block;
  width: 100%;
}

.inforbox li {
  line-height: 18px;
  list-style-type: disc;
  margin: 0 20px 10px 10px;
}

.inforbox ol li {
  line-height: 18px;
  list-style-type: none;
  text-indent: -20px;
  margin: 0 20px 6px 20px;
}

.link,
.inforbox a.link {
  color: rgb(44, 131, 185);
  font-weight: bold;
}

.extraserviceState {
  background-color: #eaeaea;
  padding: 10px;
  text-align: center;
  display: none;
}

.usebox {
  float: right;
}

.inforbox2 {
  float: left;
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0 5px 0;
  padding: 0 0 5px 6px;
  line-height: 20px;
}

.inforbox2 li {
  line-height: 18px;
  list-style-type: disc;
  margin: 0 10px 5px 10px;
}

.root .table-bottom-area .inforbox2 {
  height: auto;
  margin: 5px 0 5px 0;
  padding: 0 0 5px 6px;
  font-size: 14px;
  font-weight: 400;
  color: #4e647c;
  float: left;
  text-align: left;
  line-height: 20px;
}

.container .root .inforbox3,
.paper .inforbox3 {
  height: auto;
  margin: 0 0px 20px 0;
  font-size: 14px;
  font-weight: 400;
  color: #d84b4b;
  text-align: left;
}

.info {
  margin-bottom: 15px;
  margin-top: 20px;
  height: 20px;
}

.infoicon1 {
  height: 18px;
  padding: 3px 10px;
  background-color: #8dc6e9;
  color: #fff;
  font-size: 11px;
  line-height: 18px;
  font-weight: 600;
  border-radius: 3px;
  margin: 0 5px 0 5px;
}

.infoicon2 {
  height: 18px;
  padding: 3px 10px;
  background-color: #dfafaf;
  color: #fff;
  font-size: 11px;
  line-height: 18px;
  font-weight: 600;
  border-radius: 3px;
  margin: 0 5px 0 5px;
}


.MuiTable-root .MuiInput-root {
  padding: 0;
  margin: 0;
  height: 33px;
}
.container .root .MuiInputBase-root,
.paper .MuiInputBase-root {
  font-size: 14px;
  min-height: 31px;
  padding: 0;
  margin-right: 12px;
  margin-left: 1px;
}

.MuiOutlinedInput-root {
  background-color: #fafafa;
  padding: 5px;
  margin: 3px;
  min-width: 100%;
  min-height: 80px;
  display: block;
}

.container .root .MuiFormControl-root,
.paper .MuiFormControl-root {
  margin: 0;
  padding: 0;
}
.table-top-area .MuiFormControl-root {
  float: left;
  height: 33px;
}

.MuiFormGroup-row {
  height: 33px;
}
.sms-textfield {
  width: 100%;
  padding: 10px;
}

.sms-textfield .MuiInputBase-root textarea {
  padding: 15px;
}

.container .root .MuiPaper-elevation1 {
  box-shadow: 0 0 0 0;
}

.container .root .MuiTable-root,
.paper .MuiTable-root {
  margin-bottom: 0px;
  float: left;
}

strong {
  color: #d84b4b;
  font-weight: 600;
  margin: 0 3px 0 3px;
  font-size: 15px;
}

.td-strong-cell {
  font-weight: 700;
  background-color: #f9ffdd;
}

.td-strong-cell2 {
  font-weight: 300;
  //background-color: #f9ffdd;
  padding: 20px 0 20px 40px;
}

.multiLine-cell {
  padding: 5px 0px;
  text-align: center;
  white-space: pre-wrap;
}

.bigtitle .MuiSvgIcon-fontSizeSmall {
  margin-top: 3px;
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 16px;
  color: #3e94cb;
}

.root .MuiSvgIcon-fontSizeSmall,
.paper .MuiSvgIcon-fontSizeSmall {
  font-size: 14px;
  margin: 0 0 0 3px;
  position: relative;
  top: 2px;
}

//일반 컨텐츠용 테이블
.contentsTable {
  text-align: center;
  min-width: 70%;
  margin: 0;
}

.contentsTable .contentsTableRow {
  text-align: center;
  height: 50px;
  border-bottom: 1px solid #999;
}

.contentsTable .contentsTableRow .contentsTableCell {
  text-align: center;
  padding: 3px 5px;
}

.contentsTable .contentsTableRow .MuiTableCell-root,
.contentsTable .contentsTableRow .MuiTableCell-body {
  padding: 0 3px;
}

.innerTeble {
  margin: 20px 0 20px 0px;
}

.MuiTableBody-root .th {
  padding: 0 10px;
  border-top: 1px solid #aaa;
  background-color: #ecf8ff;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  color: #2e5281;
  width: auto;
}

.th2 {
  padding: 2px 10px;
  border-top: 1px solid #aaa;
  background-color: #ecf8ff;
  font-weight: 600;
  text-align: center;
  line-height: 1.4;
  color: #2e5281;
  width: auto;
  border-bottom: 2px solid #aaa;
}

.MuiTable-root .MuiTableBody-root .MuiTableCell-body {
  border-top: 1px solid #ccc;
  &.borderTop-none {
    border-top: none;
  }
}

.MuiTableCell-body .td {
  padding-left: 10px;
  border: 1px solid #ccc;
  background-color: #fafbfc;
  border-right: 0;
  border-left: 0;
  font-size: 14px;
  text-align: center;
}

.td2 {
  padding: 10px 10px;
  border: 1px solid #ccc;
  background-color: #fafbfc;
  border-right: 0;
  border-left: 0;
  font-size: 14px;
  text-align: center;
}

.container .root .th h5,
.paper h5 {
  font-size: 12px;
  color: #2e5281;
  display: inline-block;
}

.td .under {
  padding: 0 0 0 5px;
  margin-top: 8px;
  font-size: 14px;
  color: #076db6;
}

.inputMask {
  font-size: 16px;
  color: #333;
  font-weight: normal;
  background-color: none;
  border: none;
  outline: none;
  height: 30px;
  margin: 8px 8px;
}

.searchForm {
  width: 100%;
}

.searchForm .formControl {
  margin: 8px;
}

.searchForm .formControl .selectEmpty {
  margin-top: 16px;
}

/////////////////////////////////////////////////////////////////
///////////////////////////////* Select *////////////////////////
/////////////////////////////////////////////////////////////////

.container .root label + .MuiInput-formControl,
.paper label + .MuiInput-formControl {
  margin-top: 0px;
  min-width: auto;
}
.MuiTableCell-root .MuiFormControl-root {
  min-width: 30px;
}
.container .root .MuiInput-underline:before,
.paper .MuiInput-underline:before {
  border-bottom: 1px solid #a0a0a0;
  min-width: auto;
  padding: 0;
  margin: 0 0 0 0;
}

.container .root .MuiInput-underline:after,
.paper .MuiInput-underline:after {
  border-bottom: 2px solid #000000;
  min-width: auto;
}

input[type="text"].MuiInput-underline-autocom {
  border: 0;
  border-bottom: 1px solid #a0a0a0;
  min-width: auto;
  font-size: 14px;
  margin: 0;
  padding: 0 0 2px 8px;
  height: 30px;
}
.MuiInput-underline-autocom:focus {
  outline: none;
}
.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
  display: block;
}

.autocom-area {
  min-width: 200px;
  padding: 0;
  margin: 0;
  font-size: 14px;
  z-index: 180;
}

.MuiInput-list {
  font-size: 14px;
  border: 1px solid #1579b9;
  min-width: 160px;
  float: left;
  text-align: left;
  font-weight: 400;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: 250px;
  z-index: 200;
}

.auto-complete-option {
  min-width: 160px;
  padding: 2px 5px;
  z-index: 190;
  border: 0;
}

.container .root .MuiSelect-icon,
.paper .MuiSelect-icon {
  top: calc(50% - 12px);
  color: #64a2cc;
  position: absolute;
  pointer-events: none;
}

.container .root .MuiNativeSelect-select,
.paper .MuiNativeSelect-select {
  font-size: 14px;
}

.input-w100p {
  width: 85%;
  display: block;
}
//*********** search area ***********//
.between {
  font-size: 20px;
  margin: 0 12px 0 5px;
  height: 32px;
  display: inline-flex;
  align-items: center;
}
.search-area {
  text-align: center;
  width: 100%;
  background-color: #fafafa;
  border: 1px dotted #eaeaea;
  border-radius: 4px;
  font-weight: 400;
  padding: 10px 0px 15px 0px;
  margin: 10px 0 15px 0;
  display: flex;
  justify-content: center;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-150 {
  margin-left: 70px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-10 {
  margin-right: 10px;
}

.modify-area {
  border: 1px solid #eaeaea;
  padding: 10px 0 5px 0;
  text-align: center;
  display: block;
  margin: 5px 0px 18px 0px;
  background-color: #fafafa;
  float: left;
  width: calc(100% - 2px);
}

.summary-main-area {
  width: 100%;
  min-height: 90px;
  display: table;
  justify-content: center;
  margin-bottom: 12px;
  text-align: center;
  margin-top: 20px;
  vertical-align: middle;
}

.margin-top-10 {
  margin-top: -10px;
}

.summary-area {
  display: inline-flex;
  justify-content: space-around;
  min-width: 70%;
  min-height: 50px;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid #eaeaea;
  margin: 0px;
  padding: 0px 30px;
  background-color: #eaf9ff;
  border-radius: 5px;
}

.summary-sms-area {
  width: 100%;
  min-height: 90px;
  display: table;
  justify-content: center;
  margin-bottom: 12px;
  text-align: center;
  margin-top: 0px;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  border: 2px solid #eaeaea;
  background-color: #eaf9ff;
}

.summary-button-area {
  width: 160px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.summary-button {
  margin-bottom: 1px;
  min-width: 115px;
}

.download-area {
  justify-content: left;
  min-width: 96%;
  font-size: 13px;
  font-weight: 400;
  border: 4px solid #eaeaea;
  float: left;
  margin: 0px 0px 20px 0px;
  padding: 10px 0px 10px 20px;
  background-color: #fafafa;
  border-radius: 5px;
  line-height: 25px;
  text-align: left;
}

.table-top-area {
  margin: 10px 0 7px 0;
  display: block;
  width: 100%;
  float: left;
  text-align: left;
}

.table-top-button {
  margin: 0 5px 0 0;
  float: right;
  min-width: 40px;
}

.table-bottom-area {
  text-align: right;
  margin: 5px 0 5px 0;
  float: right;
  display: block;
  min-width: 100%;
}

.table-bottom-middle-area {
  text-align: center;
  margin: 5px 0 5px 0;
  float: right;
  display: block;
  min-width: 100%;
}

/* 2020-02-24 */
button.search-button {
  height: 30px;
  font-size: 12px;
  padding: 0 13px;
  min-width: 30px;
  background-color: #57ace0;
  color: white;
  border-radius: 3px;
  display: inline-block;
  margin: 0 0 0 10px;
  font-weight: 300;
  text-align: center;
}

div.search-button {
  height: 23px;
  font-size: 12px;
  padding: 7px 13px 0 13px;
  min-width: 30px;
  background-color: #57ace0;
  color: white;
  border-radius: 3px;
  display: inline-block;
  margin: 0 0 0 10px;
  font-weight: 300;
  cursor: pointer;
  text-align: center;
}
/* // // */

.search-button:hover {
  background-color: #1579b9;
}

.search-area.wrapSafeArea,
.wrapSafeArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -10px;
  label,
  label.label-l,
  div,
  button {
    margin-top: 10px;
  }
}
/////////////////////////* table *//////////////////////
////////////////////////////////////////////////////////
.container .table-area {
  width: 990px;
  height: auto;
  overflow: auto;
}

.container .root .MuiButtonBase-root,
.paper .MuiButtonBase-root {
  margin: 0;
}

.container .root .table-area .MuiTable-root,
.paper .table-area .MuiTable-root {
  margin-bottom: 0px;
}

.container .root .MuiTableCell-head,
.paper .MuiTableCell-head {
  border-top: #e9e9e9 1px solid;
  background-color: #ecf8ff;
  color: #2e5281;
  font-weight: 500;
  font-size: 14px;
  margin: 6px 2px 8px 2px;
  padding: 10px 0px;
  line-height: 16px;
  text-align: center;
  border-top: 1px solid #aaaaaa;
  height: 25px;
  &.borderTop-none {
    border-top: none;
  }
}

.MuiSvgIcon-root {
  fill: black;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-asc {
  .MuiTableSortLabel-icon {
    background-image: url("./images/icon-sort-up.png");
    width: 9px;
    height: 15px;
  }
}
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-desc {
  .MuiTableSortLabel-icon {
    background-image: url("./images/icon-sort-down.png");
    width: 9px;
    height: 15px;
  }
}
/*2020-02-24 수정 */
.MuiTableSortLabel-icon {
  width: 9px !important;
  height: 15px !important;
  position: absolute;
  right: -17px;
  top: calc(50% - 5px);
}
/*// // */

.table-head-line {
  border-bottom: 1px solid #ccc;
}

.container .root .MuiTableCell-root,
.paper .MuiTableCell-root {
  word-break: break-all;
}

.MuiTableBody-root .MuiTableCell-root {
  padding: 10px 7px;
}

.MuiTableBody-root .checkbox,
.MuiTableBody-root .ignoreClick {
  text-align: center;
  padding: 10px 0px !important;
}

.MuiTableHead-root .MuiTableRow-head .MuiTableCell-alignCenter {
  padding: 10px 0;
  height: 25px;
  line-height: 23px;
}

/* Pagination */
.container .root .MuiButton-textSecondary,
.paper .MuiButton-textSecondary {
  color: #0093f5;
  margin: 10px 0 10px 10px;
  font-size: 16px;
}

.container .root .MuiButton-textPrimary,
.paper .MuiButton-textPrimary {
  color: #7e7e7e;
  margin-left: 7px;
}

button.MuiFlatPageButton-rootCurrent,
button.MuiFlatPageButton-rootStandard {
  padding: 3px 12px;
}

table td {
  height: 35px;
}

table .regist td {
  height: 0px;
}

//////////////////////////////////////////////////////
////////////////////////* TAB */////////////////////
//////////////////////////////////////////////////////
.MuiAppBar-root {
  margin-bottom: 20px;
}

.root .MuiPaper-elevation4,
.paper .MuiPaper-elevation4 {
  box-shadow: none;
}

.MuiTabs-root {
  background: white;
  border-bottom: 1px solid #aabac2;
  min-height: 40px;
  padding: 0;
}

.root .PrivateTabIndicator-colorPrimary-132,
.paper .PrivateTabIndicator-colorPrimary-132 {
  background-color: #1ca8ff;
}

.MuiTab-wrapper {
  padding: 0 5px;
  font-size: 13px;
}

.root .MuiTab-root,
.paper .MuiTab-root {
  min-width: 100px;
  background-color: #71b8e4;
  font-size: 13px;
  font-weight: 400;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  font-weight: normal;
  line-height: 1.2em;
  color: #fff;
  padding: 12px;
}
.MuiTabs-flexContainer .MuiButtonBase-root {
  margin-right: 2px !important;
}

.root .MuiTab-textColorPrimary.Mui-selected,
.paper .MuiTab-textColorPrimary.Mui-selected {
  color: #ffffff;
  background-color: #2c83b9;
  font-size: 13px;
  font-weight: 500;
}

.MuiTabs-indicator {
  height: 2px;
  background-color: #1e81be;
  margin-top: 0;
}

.root .PrivateTabIndicator-root-173,
.paper .PrivateTabIndicator-root-173 {
  background-color: #2e5281;
  height: 4px;
}

//////////////////////////////////////////////////////
////////////////////////* Radio */////////////////////
//////////////////////////////////////////////////////

.container .root .MuiRadio-colorPrimary.Mui-checked,
.paper .MuiRadio-colorPrimary.Mui-checked {
  color: #2e5281;
}

.MuiFormControlLabel-root .MuiIconButton-root {
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 0;
}

.container .root .MuiIconButton-colorPrimary,
.paper .MuiIconButton-colorPrimary {
  margin-top: 0px;
}

.container .root .MuiIconButton-colorPrimary:hover {
  border-radius: 15px;
}
.MuiButton-textPrimary:hover {
  border-radius: 22px;
}
.MuiButton-textSecondary:hover {
  border-radius: 22px;
}

.container .root .MuiFormControlLabel-root,
.paper .MuiFormControlLabel-root {
  margin: 0;
  padding: 0px;
}

.MuiFormControlLabel-root .MuiTypography-root {
  font-size: 14px;
  margin: 0px 5px 0px 5px;
}

//////////////////////////////////////////////////////
////////////////////////* Label */////////////////////
//////////////////////////////////////////////////////
.search-area label,
.download-area label,
.modify-area label,
.table-top-area label {
  padding: 0px 10px 0px 15px;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #1579b9;
  text-align: center;
}

.download-area .label,
.search-area .label,
.table-top-area .label {
  display: inline-flex;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  margin: 0 15px 0 0;
  height: 30px;
  color: #1579b9;
}

.search-area .MuiFormControl-root,
.search-area .MuiTextField-root,
.search-area label,
.search-area .MuiInputBase-root,
.search-area .MuiInput-root,
.search-area .MuiInput-underline,
.search-area button,
.search-area span,
.modify-area .MuiFormControl-root,
.modify-area .MuiTextField-root,
.modify-area label,
.modify-area .MuiInputBase-root,
.modify-area .MuiInput-root,
.modify-area .MuiInput-underline,
.modify-area button,
.modify-area span {
  float: left;
}
.block {
  display: inline-block;
}

.td label {
  font-size: 14px;
  margin: 0 5px 0 0;
  color: #1579b9;
  font-weight: 400;
}

.text-graph {
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 80px;
  min-width: 180px;
  position: relative;
}

.text-graph label {
  padding: 10px 0px 10px 0px;
  margin: 0px 0px 6px 0px;
  border-bottom: 1px #aec2db dotted;
  line-height: 14px;
  width: 100%;
  color: #2e5281;
  font-size: 14px;
  word-break: keep-all;
  position: absolute;
  top: 7px;
  left: 0px;
}

.text-graph span {
  position: absolute;
  top: 48px;
  width: 100%;
  left: 0px;
}
.text-graph a {
  padding: 10px 15px;
  cursor: pointer;
}

/* 2020-02-24 수정 */
.container .root .MuiFormLabel-root,
.paper .MuiFormLabel-root {
  color: #2590bc;
  font-weight: 400;
  padding: 7px 7px 7px 7px;
  border-radius: 4px;
  background-color: #f2f2f2;
  margin: 0 5px 0 0;
  top: 3px;
}
/* // // */

.container .root .MuiInputLabel-formControl,
.paper .MuiInputLabel-formControl {
  margin: 0;
  min-width: 78px;
  transform: none;
  font-size: 13px;
}

.paper .inner #bankbookPostfix {
  font-size: 14px;
}
/* Date picker */
.date-label {
  top: 20px;
  left: 20px;
}

/* Checkbox */
.paper .MuiCheckbox-colorSecondary {
  height: 15px;
}

.root .MuiCheckbox-colorSecondary.Mui-checked,
.paper .MuiCheckbox-colorSecondary.Mui-checked {
  color: #2e5281;
}

.root .MuiCheckbox-root,
.paper .MuiCheckbox-root {
  color: #2e5281;
  margin-top: 0px;
  padding: 0;
  border-radius: 0;
}

/* Text Box */
.container .root .MuiInputBase-input,
.paper .MuiInputBase-input {
  font-size: 14px;
  margin: 0;
}

.MuiTable-root .MuiInputBase-input {
  min-width: auto;
  padding-left: 10px;
}

.root .MuiInputBase-inputSelect,
.paper .MuiInputBase-inputSelect {
  height: 30px;
}

textarea.MuiInputBase-input {
  padding: 10px;
}
.MuiInputBase-root .MuiSelect-select {
  padding: 3px 20px 3px 10px;
}

/* btn-area */
.btn-area {
  overflow: hidden;
}

.btn-area a,
.btn-area button {
  margin: 0 3px;
  vertical-align: top;
}

.btn-area a:first-child,
.btn-area button:first-child {
  margin-left: 0;
}

.btn-area a:last-child,
.btn-area button:last-child {
  margin-right: 0;
}

.btn-area p {
  margin: 0 3px;
  vertical-align: top;
}

/* Modal */
.paper {
  width: 1200px;
  height: auto;
  min-height: 450px;
  max-height: 840px;
  margin: 70px auto 0px auto;
  border-radius: 10px;
  border-color: #fff;
  background-color: #ffffff;
  overflow-y: auto;
}

.paper .inner {
  height: auto;
  min-height: 450px;
  padding: 0px 30px 60px 30px;
  overflow-y: hidden;
  overflow-x: hidden;
}
// 모달 최상단 '닫기' 버튼
.modal-top-area {
  text-align: right;
  height: 0px;
  display: inline-table;
  width: 100%;
}
.inner .MuiButton-root {
  min-width: 20px;
}

/*2020-02-24  수정*/
.paper .btn-close {
  height: 24px;
  background-image: url(./images/btn_close.png1.png);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: block;
  top: 20px;
  width: 24px;
}
/*  top: 83px;
  padding: 0;
  margin: 0;
  position: fixed;
  right: 50%;
  margin-right: -567px;
}

//아이디찾기모달
.paper .btn-close-findId {
  height: 20px;
  background-image: url(./images/btn_close.png1.png);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: block;
  top: 90px;
  padding: 0;
  margin: 0;
  position: fixed;
  right: 50%;
  margin-right: -275px;
}

//전자세금계산서 인쇄모달
.paper .btn-close-taxbillPrint {
  height: 20px;
  background-image: url(./images/btn_close.png1.png);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: block;
  top: 90px;
  padding: 0;
  margin: 0;
  position: fixed;
  right: 50%;
  margin-right: -389px;
}

//SMS발송모달
.paper .btn-close-smsDirectSend {
  height: 20px;
  background-image: url(./images/btn_close.png1.png);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: block;
  top: 90px;
  padding: 0;
  margin: 0;
  position: fixed;
  right: 50%;
  margin-right: -347px;
}

//이용요금청구서
.paper .btn-close-charge {
  height: 20px;
  background-image: url(./images/btn_close.png1.png);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: block;
  top: 90px;
  padding: 0;
  margin: 0;
  position: fixed;
  right: 50%;
  margin-right: -456px;
}

//라벨지 미리보기
.paper .btn-close-label {
  height: 20px;
  background-image: url(./images/btn_close.png1.png);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: block;
  top: 90px;
  padding: 0;
  margin: 0;
  position: fixed;
  right: 50%;
  margin-right: -470px;
}

//자동납부 동의자료
.paper .btn-close-evidence {
  height: 20px;
  background-image: url(./images/btn_close.png1.png);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: block;
  top: 90px;
  padding: 0;
  margin: 0;
  position: fixed;
  right: 50%;
  margin-right: -552px;
}

//출금대상추가
.paper .btn-close-cmsReceiptRequestTargetAddModal {
  height: 20px;
  background-image: url(./images/btn_close.png1.png);
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: block;
  top: 90px;
  padding: 0;
  margin: 0;
  position: fixed;
  right: 50%;
  margin-right: -593px;
}
/*/

.paper .btn-close:hover {
  background-image: url(./images/btn_close.png1.png);
  opacity: 0.7;
  background-color: #fff;
}

/* Alert */
.MuiDialogActions-root {
  justify-content: center;
}

.MuiDialog-paperWidthSm {
  max-width: 600px;
  min-width: 400px;
}

.alert-btn-agree {
  color: #ffffff;
  padding: 7px 15px;
  border-radius: 4px;
  background-color: rgb(57, 153, 221);
  font-family: "Roboto", "Noto Sans KR", "Arial", sans-serif;
}

.alert-btn-agree:hover {
  background-color: #5bbafd;
}

.alert-btn-disagree {
  color: #ffffff;

  padding: 7px 15px;
  border-radius: 4px;
  background-color: rgb(235, 134, 125);
  font-family: "Roboto", "Noto Sans KR", "Arial", sans-serif;
}

.alert-btn-disagree:hover {
  background-color: #ffb1aa;
}

/* .select-area */
.select-area .selectric-wrapper {
  min-width: 110px;
}

/* .select-tab */
.tables {
  display: none;
}

/* Calender */
.MuiPaper-elevation8 {
  border: 1px solid #ccc;
  box-shadow: none !important;
}

.MuiButton-label h4 {
  font-size: 20px;
  font-weight: 300;
}

.MuiButton-label h5 {
  font-size: 10px;
  font-weight: 300;
}

.MuiButton-label h6 {
  font-size: 17px;
  color: #d5efff;
  font-weight: 300;
  background-color: #555;
  margin-right: 10px;
  margin-left: 10px;
}

.MuiTypography-h4 {
  font-size: 20px;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: #2e5281;
}

.MuiPaper-root .MuiPickersBasePicker-container .MuiToolbar-gutters {
  background-color: #666;
  padding: 0;
  margin: 0;
  height: 30px;
}

.MuiPickersBasePicker-container button {
  margin: 1px;
  padding: 0;
  align-self: center;
}

.MuiPickersDatePickerRoot-toolbar {
  align-items: unset !important;
  flex-direction: row !important;
}

.MuiPickersToolbarText-toolbarTxt {
  padding: 0 10px;
}

.MuiTouchRipple-root {
  padding: 0;
  margin: 0;
  display: none;
}

/* table title */
.table-title {
  height: 30px;
  line-height: 30px;
}

.table-title i {
  color: #ec138d;
}

/* table */

.vertical-type {
  margin: 10px 0 0;
  min-width: 1200px;
}

.vertical-type table {
  border: 1px solid #ddd;
  min-width: 500px;
}

.vertical-type table thead tr th {
  padding: 10px 0;
  border-top: 1px solid #ccc;
  background-color: #ecf8ff;
  font-weight: normal;
}

.vertical-type table thead tr:first-child th {
  border-top: 0;
}

.vertical-type table thead tr th button {
  position: relative;
  top: -1px;
  padding: 0 7px;
}

.vertical-type table tbody tr td {
  padding: 10px 0;
  text-align: center;
  border-top: 1px solid #ddd;
  background-color: #fafbfc;
}

.vertical-type table tbody tr:hover {
  border-left: 1px solid #3e94cb;
  border-right: 1px solid #3e94cb;
}

.vertical-type table tbody tr:hover td {
  border-top: 1px solid #3e94cb;
  border-bottom: 1px solid #3e94cb;
  background-color: #fff;
}

.vertical-type table tbody tr td.align-left {
  text-align: left;
}

.vertical-type table tbody tr td.align-right {
  text-align: right;
}

////////////////////////////////////////////////
///////////////////*버튼 *//////////////////////
////////////////////////////////////////////////
.btn {
  overflow: hidden;
  display: inline-block;
  margin: 0;
  padding: 0 22px;
  border-radius: 16px;
  background-color: #fff;
}

/* 2020-02-24 수정*/
.btntop {
  overflow: hidden;
  display: inline-block;
  margin: 0 2px;
  padding: 0 12px;
  border-radius: 3px;
  background-color: rgb(240, 240, 240);
  color: #838383;
  height: 24px;
  line-height: 22px;
  font-size: 11px;
  font-weight: 400;
  border: 1px solid #cccccc;
  /* 2021-03-10 수정*/
  &.btnlogintop {
    background-color: #2e5281;
    border: 2px solid #2e5281;
    color: #fff;
    &:hover {
      border: 2px solid #2e5281;
      background-color: #fff;
      color: #2e5281;
    }
  }
}
/* // // */

.btntop:hover {
  background-color: rgb(224, 224, 224);
}

.btn-disabled {
  opacity: 0.4;
}
/* 2020-02-24 수정 */
.btn-s,
.btn-xs,
button.btn-s,
a.btn-s {
  font-size: 12px;
  padding: 0 7px;
  vertical-align: middle;
  border-radius: 3px;
  display: inline-block;
  margin: 1px 0 1px 2px;
  cursor: pointer;
  font-weight: 400;
  height: 25px;
  line-height: 15px;
  text-align: center;
  background-color: #1777ce;
  border: 1px solid #1777ce;
  color: #fff;
  min-width: 60px;
}

div.btn-s {
  padding: 0 7px;
  margin: 0 4px;
  min-width: 66px;
  line-height: 24px;
}
div.btn-xs {
  min-width: 35px;
  line-height: 24px;
  margin: 4px;
  padding: 0 3px;
}
/* // //*/

.btn-s:hover,
.btn-l6:hover,
.btn-xs:hover {
  background-color: #1579b9;
}

.search-area .btn-file,
.modify-area .btn-file {
  height: 30px;
  min-width: 80px;
  font-size: 12px;
  background-color: #57ace0;
  color: white;
  border-radius: 13px;
  display: inline-block;
  margin: 0 0 0 5px;
  padding: 0;
  font-weight: 300;
  text-align: center;
}

/* 2020-02-24 수정 */
.btn-m,
.paper .btn-m {
  overflow: hidden;
  display: inline-block;
  margin: 1px 5px 1px 5px;
  padding: 5px 14px;
  border-radius: 3px;
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
}
/* // // */

button > .btn-m {
  line-height: 14px;
  padding: 5px 15px;
}

.btn-m:hover,
.paper .btn-m:hover,
.paper .btn-file:hover {
  background-color: #1579b9;
  color: #fff;
}

.search-area .btn-m,
.paper .search-area .btn-m {
  height: 30px;
}

/* 2020-02-24 수정 */
.btn-l,
.paper .btn-l {
  overflow: hidden;
  display: inline-block;
  margin: 1px 5px 1px 5px;
  padding: 5px 15px;
  border-radius: 3px;
  border: 2px solid #2e5281;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
  background-color: #2e5281;
  color: #fff;
}
/* // // */

button > .btn-l {
  line-height: 24px;
}

/* 2020-02-24 수정 */
.btn-l:not([disabled]):hover,
.paper .btn-l:not([disabled]):hover {
  background-color: #fff;
  color: #2e5281;
}

.btn-l[disabled] {
  opacity: 0.3;
}

.btn-l2,
.paper .btn-l2 {
  overflow: hidden;
  display: inline-block;
  padding: 5px 7px;
  border-radius: 3px;
  border: 2px solid #1777ce;
  font-size: 13px;
  font-weight: 300;
  line-height: 14px;
  cursor: pointer;
  background-color: #1777ce;
  color: #fff;
}
.btn-l3,
.paper .btn-l3 {
  overflow: hidden;
  display: inline-block;
  padding: 5px 7px;
  border-radius: 3px;
  border: 2px solid #1777ce;
  font-size: 13px;
  font-weight: 300;
  line-height: 14px;
  cursor: pointer;
  background-color: #1777ce;
  color: #fff;
}
/* // // */
.btn-l2:hover,
.paper .btn-l2:hover,
.btn-l3:hover,
.paper .btn-l3:hover,
.btn-l4:hover,
.paper .btn-l4:hover {
  background-color: #fff;
  color: #1579b9;
  font-weight: 500;
}
.btn-l4,
.paper .btn-l4 {
  overflow: hidden;
  display: inline-block;
  padding: 5px 7px;
  border-radius: 16px;
  border: 2px solid #3e28bd;
  font-size: 13px;
  font-weight: 300;
  line-height: 14px;
  cursor: pointer;
  background-color: #3e28bd;
  color: #fff;
}

.btn-l5,
.paper .btn-l5 {
  overflow: hidden;
  display: inline-block;
  padding: 5px 7px;
  border-radius: 3px;
  border: 2px solid #999;
  font-size: 13px;
  font-weight: 300;
  line-height: 14px;
  cursor: pointer;
  background-color: #999;
  color: #fff;
}

.btn-l6,
.button.btn-l6,
.paper .btn-l6 {
  overflow: hidden;
  display: inline-block;
  margin: 1px 5px 1px 5px;
  padding: 5px 15px;
  border-radius: 3px;
  border: 2px solid #2e5281;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  cursor: pointer;
  background-color: #2e5281;
  color: #fff;
}

div.btn-l6 {
  padding: 0 7px;
  margin: 0 4px;
  min-width: 66px;
  line-height: 24px;
}

a.btn {
  height: 28px;
  line-height: 28px;
}

.btn.purple {
  color: #b155ad;
  border: 2px solid #b155ad;
}

.btn.red {
  color: #c00c3f;
  border: 2px solid #c00c3f;
}

.btn.gray {
  color: #6d6e71;
  border: 2px solid #6d6e71;
}

.btn.blue {
  color: #1579b9;
  border: 2px solid #1579b9;
  font-size: 13px;
  font-weight: 600;
}

.btn.light-gray {
  color: #ddd;
  border: 2px solid #e6e6e6;
}

.btn.bg-type.gray {
  color: #fff;
  background-color: #888;
}

.btn-toggle {
  overflow: hidden;
  display: inline-block;
  height: 28px;
  margin: 1px 6px 0 6px;
  line-height: 22px;
  border: 1px solid #888;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
}

.btn-top {
  overflow: hidden;
  display: inline-block;
  height: 28px;
  margin: 1px 6px 0 6px;
  line-height: 26px;
  background-color: #318dc8;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  padding: 0 10px;
}

.hidden {
  display: none;
}

//담당자정보 - 지사관리 모달 - 테이블 내 버튼 간격 조정
.margin-btn {
  margin: 0 5px;
}

//담당자정보 - 지사관리 모달 - 테이블 최상단 '지사추가' 버튼 조정
.btn-add {
  margin-top: 0px;
  height: 30px;
}

//담당자정보 - 테이블 내 담당자명 mouse over 이벤트 처리
.show-detail:hover {
  text-decoration: underline;
  cursor: pointer;
}

label.label-l,
span.label-l {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin: 0px 5px 0 15px;
  padding: 0;
  height: 32px;
  color: #1c93c2;
}

.label-l2 {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin: 0 15px 0 0;
  height: 32px;
  color: #2e5281;
}

.label-r {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin: 0 20px 0 5px;
  height: 32px;
  color: #1c93c2;
}

.radio-label-l {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  margin: 0 7px 0 5px;
  color: #545454;
}

.radio-label-r {
  display: flex;
  align-items: center;
  margin-right: 15px;
  color: #545454;
}

//파일 추가 시
.preview-section {
  margin: 0;
  text-align: center;
  width: 100%;
  max-width: 100%;
  max-height: 700px;
  overflow: auto;
}

.preview-image {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  max-width: 910px;
  border: 1px solid #eaeaea;
}

//버튼에 disabled 속성 있을 시 or 출금제외 대상으로 선택시
/*.btn-m:disabled,
.btn-s:disabled,
.btn-s.btn-exempted {
  background-color: #fff;
  color: #808080;
  border: 2px solid #808080;
}
*/
//disabled 속성 버튼에 mouse over 시
.btn-m:disabled:hover,
.btn-s:disabled:hover,
.btn-l6:disabled:hover {
  background-color: #fff;
  color: #808080;
  border: 2px solid #808080;
}

//툴팁 아이콘 mouse over 시 커서 변경
.pointer {
  cursor: pointer;
}

.cell-tool-tip {
  position: absolute;
  border-radius: 10px;
  background-color: #2e5281;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  padding: 10px;
  width: 120px;
  height: auto;
  opacity: 1;
  text-align: center;
  margin-top: 5px;
}

//모달 내 툴팁
.modal-cell-tool-tip {
  border-radius: 10px;
  background-color: #2e5281;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  padding: 10px;
  width: 120px;
  height: auto;
  opacity: 1;
  z-index: 100;
  text-align: center;
  position: absolute;
  top: 40px;
  left: -16px;
}

//종이영수증 & 종이(세금)계산서 인쇄 영역 설정
.bill-print-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  page-break-after: always;
  width: 100%;
  width: 20cm;
  // max-width: 100%;
  margin: 0 auto;
  // height: 25cm;
  font-size: 13px;
  // float: left;
  height: 260mm;
}
.donationbill-bottom {
  margin-bottom: 35px;
}

.bill-print-area table {
  display: inline-table;
}

//기부금 영수증 인쇄 영역 설정
.donationbill-print-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  page-break-after: always;
  width: 100%;
  width: 20cm;
  // max-width: 100%;
  margin: 0 auto;
  // height: 25cm;
  font-size: 13px;
  // float: left;
  height: 280mm;
  margin-bottom: 50px;
  & .donationbill-print-inner {
    height: 280mm;
  }
}
@media print {
  .donationbill-print-area {
    margin-bottom: 0;
    height: 278mm;
    width: 210mm;
    border: 10px solid white;
  }
}

.eee {
  width: fit-content;
  height: fit-content;
  display: inline-block;
}
.donationtable {
  display: inline-table;
  text-align: center;
}
.donationtable tr,
.donationtable td {
  height: 25px;
  line-height: 16px;
  border: 1px solid gray;
  vertical-align: middle;
}
.donationtable tr {
  border-left: none;
  border-right: none;
}
.donationtable th:first-child,
.donationtable td:first-child {
  border-left: none;
}
.donationtable th:last-child,
.donationtable td:last-child {
  border-right: none;
}

.donationtableSerial {
  display: inline-table;
  text-align: center;
  width: auto;
  margin: 10px 0 10px 0;
  float: left;
}
.donationtableSerial tr,
.donationtableSerial th {
  height: 20px;
  border: 1px solid gray;
  padding: 0 5px;
}
.donationtable .title {
  font-weight: bold;
  padding-left: 10px;
}
.donationtable .space {
  height: 5px;
  border-top: 2px solid gray;
  border-bottom: 2px solid gray;
}
//종이(세금)계산서 공급받는자 영역 내 테이블
.tax-bill-recipient {
  color: blue;
  width: 100%;
}

.tax-bill-recipient table {
  border: 1px solid blue;
  border-collapse: collapse;
  text-align: center;
}

.tax-bill-recipient th,
.tax-bill-recipient td {
  border: 1px solid blue;
  height: 20px;
}
ck {
  color: black;
}

.donation-bill-recipient table {
  border: 1px solid;
  width: 200px;
}

//종이(세금)계산서 중간 구분선
.MuiDivider-root.bill-print-middleLine {
  border: 1px dashed;
  display: block;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}
.tax-bill-recipient .text-black {
  color: black;
}

//종이(세금)계산서 공급받는자 영역 내 테이블
.tax-bill-recipientRed {
  color: red;
  width: 100%;
}

.tax-bill-recipientRed table {
  border: 1px solid red;
  text-align: center;
  border-collapse: collapse;
}

.tax-bill-recipientRed th,
.tax-bill-recipientRed td {
  border: 1px solid red;
  height: 20px;
}

.tax-bill-recipientRed .text-black {
  color: black;
}

.paperbill-height-40 {
  height: 40px;
}
.paperbill-height-30 {
  height: 30px;
}

//모달 내 가로스크롤 적용 테이블 영역
.modal-overflow-table {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: inline-block;
}

//모달 내 가로스크롤 적용 테이블 스크롤 영역 제한
.left-table-div-scroll {
  float: left;
  width: 10%;
}

.right-table-div-scroll {
  float: right;
  width: 80%;
  overflow: auto;
}

//모달 내 세로스크롤 적용 테이블 영역
.modal-overflow-y-table {
  max-width: 100%;
  max-height: 460px;
  overflow-x: hidden;
  overflow-y: auto;
  display: inline-block;
}

//동의자료 사후점검 - 사후점검 순서 내 단계 표기 박스
.post-check-step {
  display: inline-block;
  vertical-align: middle;
  width: 285px;
  padding: 15px 7px;
  margin: 20px 10px 15px 10px;
  background-color: #dedede;
  border-radius: 15px;
  color: gray;
  text-align: center;
}
.post-check-icon {
  display: inline-block;
  vertical-align: middle;
}

//동의자료 사후점검 - 사후점검 순서 내 단계 표기 박스 현재 단계
.post-check-step.on-step {
  background-color: #1579b9;
  color: #fff;
}

//공통 적용 - axios 요청 중 loading image 출력 영역
.loading-image-area {
  position: fixed;
  z-index: 1500;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-image {
  width: 70px;
  height: 70px;
}

//////////가로폭///////////
.w10 {
  width: 10px;
}

.w20 {
  width: 20px;
}

.w30 {
  width: 30px;
}

.w40 {
  width: 40px;
}

.w50 {
  width: 50px;
}

.w60 {
  width: 60px;
}

.w70 {
  width: 70px;
}

.w80 {
  width: 80px;
}

.w90 {
  width: 90px;
}

.w100 {
  width: 100px;
}

.w110 {
  width: 110px;
}

.w120 {
  width: 120px;
}

.w130 {
  width: 130px;
}

.w140 {
  width: 140px;
}

.w150 {
  width: 150px;
}

.w160 {
  width: 160px;
}

.w170 {
  width: 170px;
}

.w180 {
  width: 180px;
}

.w190 {
  width: 190px;
}

.w200 {
  width: 200px;
}

.w210 {
  width: 210px;
}

.w220 {
  width: 220px;
}

.w230 {
  width: 230px;
}

.w240 {
  width: 240px;
}

.w250 {
  width: 250px;
}

.w260 {
  width: 260px;
}

.w270 {
  width: 270px;
}

.w280 {
  width: 280px;
}

.w290 {
  width: 290px;
}

.w300 {
  width: 300px;
}

.w310 {
  width: 310px;
}

.w320 {
  width: 320px;
}

.w330 {
  width: 330px;
}

.w340 {
  width: 340px;
}

.w350 {
  width: 350px;
}

.w360 {
  width: 360px;
}

.w370 {
  width: 370px;
}

.w380 {
  width: 380px;
}

.w390 {
  width: 390px;
}

.w400 {
  width: 400px;
}

.w410 {
  width: 410px;
}

.w420 {
  width: 420px;
}

.w430 {
  width: 430px;
}

.w440 {
  width: 440px;
}

.w450 {
  width: 450px;
}

.w460 {
  width: 460px;
}

.w470 {
  width: 470px;
}

.w480 {
  width: 480px;
}

.w490 {
  width: 490px;
}

.w500 {
  width: 500px;
}

.w510 {
  width: 510px;
}

.w520 {
  width: 520px;
}

.w530 {
  width: 530px;
}

.w540 {
  width: 540px;
}

.w550 {
  width: 550px;
}

.w560 {
  width: 560px;
}

.w570 {
  width: 570px;
}

.w580 {
  width: 580px;
}

.w590 {
  width: 590px;
}

.w600 {
  width: 600px;
}

.w610 {
  width: 610px;
}

.w620 {
  width: 620px;
}

.w630 {
  width: 630px;
}

.w640 {
  width: 640px;
}

.w650 {
  width: 650px;
}

.w660 {
  width: 660px;
}

.w670 {
  width: 670px;
}

.w680 {
  width: 680px;
}

.w690 {
  width: 690px;
}

.w700 {
  width: 700px;
}

.w710 {
  width: 710px;
}

.w720 {
  width: 720px;
}

.w730 {
  width: 730px;
}

.w740 {
  width: 740px;
}

.w750 {
  width: 750px;
}

.w760 {
  width: 760px;
}

.w770 {
  width: 770px;
}

.w780 {
  width: 780px;
}

.w790 {
  width: 790px;
}

.w800 {
  width: 800px;
}

.w810 {
  width: 810px;
}

.w820 {
  width: 820px;
}

.w830 {
  width: 830px;
}

.w840 {
  width: 840px;
}

.w850 {
  width: 850px;
}

.w860 {
  width: 860px;
}

.w870 {
  width: 870px;
}

.w880 {
  width: 880px;
}

.w890 {
  width: 890px;
}

.w900 {
  width: 900px;
}

.w910 {
  width: 910px;
}

.w920 {
  width: 920px;
}

.w930 {
  width: 930px;
}

.w940 {
  width: 940px;
}

.w950 {
  width: 950px;
}

.w960 {
  width: 960px;
}

.w970 {
  width: 970px;
}

.w980 {
  width: 980px;
}

.w990 {
  width: 990px;
}

.w1000 {
  width: 1000px;
}

.w1050 {
  width: 1052px;
}

.w1100 {
  width: 1100px;
}

.w1150 {
  width: 1150px;
}

.w1160 {
  width: 1160px;
}

.w1170 {
  width: 1170px;
}

.w1180 {
  width: 1180px;
}

.w1190 {
  width: 1190px;
}

.w1200 {
  width: 1200px;
}

.w1250 {
  width: 1250px;
}

.w1260 {
  width: 1260px;
}

.w1270 {
  width: 1270px;
}

.w1280 {
  width: 1280px;
}

.w1300 {
  width: 1300px;
}

.w1400 {
  width: 1400px;
}

.w1500 {
  width: 1500px;
}

.w1600 {
  width: 1600px;
}

.w1700 {
  width: 1700px;
}

.w1800 {
  width: 1800px;
}

.w1900 {
  width: 1900px;
}

.w100p {
  width: 95%;
}

.w90p {
  width: 90%;
}

.w80p {
  width: 80%;
}

.w70p {
  width: 70%;
}

.w60p {
  width: 60%;
}

.w50p {
  width: 50%;
}

.w40p {
  width: 40%;
}

.w30p {
  width: 30%;
}

.w20p {
  width: 20%;
}

.w10p {
  width: 10%;
}
/* /////////세로폭////////// */
.h05 {
  height: 5px;
}

.h10 {
  height: 10px;
}

.h15 {
  height: 15px;
}

.h20 {
  height: 20px;
}

.h21 {
  height: 21px;
}

.h22 {
  height: 22px;
}

.h23 {
  height: 23px;
}

.h24 {
  height: 24px;
}

.h25 {
  height: 25px;
}

.h26 {
  height: 26px;
}

.h27 {
  height: 27px;
}

.h28 {
  height: 28px;
}

.h29 {
  height: 29px;
}

.h30 {
  height: 30px;
}

.h40 {
  height: 40px;
}

.h50 {
  height: 50px;
}

.h60 {
  height: 60px;
}

.h70 {
  height: 70px;
}

.h80 {
  height: 80px;
}

.h90 {
  height: 90px;
}

.h100 {
  height: 100px;
}

.h110 {
  height: 110px;
}

.h120 {
  height: 120px;
}

.h130 {
  height: 130px;
}

.h140 {
  height: 140px;
}

.h150 {
  height: 150px;
}

.h160 {
  height: 160px;
}

.h170 {
  height: 170px;
}

.h180 {
  height: 180px;
}

/* //////////Color////////// */
.blue1 {
  color: #3e94cb;
}

.blue2 {
  color: #1579b9;
}

.blue3 {
  color: #2e5281;
}

.red1 {
  color: #e48982;
}

.red2 {
  color: #ec756b;
}

.red3 {
  color: #f44336;
}

/* //////////align////////// */
.fl {
  float: left;
}

.fr {
  float: right;
}

.tl {
  text-align: left;
}

.tr {
  text-align: right;
}

.tc {
  text-align: center;
}

.vt {
  vertical-align: text-top;
}

.vc {
  vertical-align: middle;
}

.vb {
  vertical-align: text-bottom;
}

.centered {
  width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.centered-w95 {
  width: 95%;
  text-align: center;
  margin : auto;
}

////////////////////////////////////////////////
///////////////////*margin*//////////////////////
////////////////////////////////////////////////
.nomargin {
  margin: 0;
  &.MuiInputBase-root{
    margin:0;
  }
}

.padding-l10 {
  padding-left: 10px;
}

.padding-l20 {
  padding-left: 20px;
}

.padding-l30 {
  padding-left: 30px;
}

.padding-l40 {
  padding-left: 40px;
}

.padding-r40 {
  padding-right: 40px;
}

.padding-l50 {
  padding-left: 50px;
}

td.padding-20 {
  padding: 40px 20px;
  line-height: 26px;
}

.padding-5 {
  padding: 5px 100px;
  float: right;
}

.margin-t50 {
  margin-top: 50px;
}

.margin-b50 {
  margin-bottom: 50px;
}

////////////////////////////////////////////////
///////////////////*달력*//////////////////////
////////////////////////////////////////////////
.MuiPickersCalendarHeader-switchHeader {
  display: flex;
  margin-top: 4px;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  border-bottom: #2e5281 1px solid;
  padding-bottom: 10px;
}

.MuiTableCell-root .MuiTypography-body1 {
  padding: 0;
  margin: 0;
  //display: none;
}
.MuiPickersCalendarHeader-iconButton {
  margin: 0px;
}

.MuiPickersCalendarHeader-dayLabel {
  color: #2e5281;
}

.MuiPickersCalendar-transitionContainer {
  background-color: #ffffff;
}

.MuiPickersBasePicker-pickerView {
  background-color: #ffffff;
  padding: 3px 5px 5px 5px;
}

/* meterial-ui css */
.MuiList-root .nested {
  padding-left: 32px;
}

/*위치조종 */
.check-astro {
  color: #e94e56;
  margin: 0 0 0 5px;
  font-size: 15px;
}

.box-preview {
  border: 1px dashed blue;
}

.display-none {
  display: none;
}

.display-block {
  display: inline-block;
  padding: 0;
}

/*통계 관련 CSS 추가*/
.scroll-table {
  width: 1180px;
  display: inline-block;
}
.left-table-div {
  float: left;
  width: 65%;
}

.right-table-div {
  float: right;
  width: 35%;
  overflow: auto;
}

.left-table-div-top {
  float: left;
  width: 35%;
}

.right-table-div-top {
  float: right;
  width: 65%;
  overflow: auto;
}

.left-table-div-cms {
  float: left;
  width: 10%;
}

.right-table-div-cms {
  float: right;
  width: 90%;
  overflow: auto;
}

/** 부가서비스 관련 **/
.smsalam-table-top {
  width: 305px;
  display: inline-block;
  padding: 0;
}

.sms-message-area {
  margin: 0 0 0 35px;
  border: 0;
}

.sms-message-textbox {
  width: 300px;
  height: 230px;
  padding: 10px 20px;
  margin-bottom: 5px;
  display: inline-table;
  border: 2px solid #a7d4ff;
  border-radius: 13px;
  background-color: #e0f2ff;
}

.sms-message-textbox .title {
  margin: 3px 0 12px 0;
  font-size: 18px;
  color: #24508a;
  font-weight: 500;
}

.sms-message-textbox .return-num {
  margin: 0px 0 0 0;
  display: block;
}

.sms-message-textbox label {
  font-size: 13px;
  margin-right: 20px;
  color: #1579b9;
  font-weight: 600;
}

.sms-message-textbox .MuiOutlinedInput-inputMultiline {
  padding: 10px;
}

.lms-message-textbox {
  width: 300px;
  height: 230px;
  padding: 10px 10px;
  margin-bottom: 5px;
  display: inline-table;
  border: 2px solid #a7d4ff;
  border-radius: 13px;
  background-color: #e0f2ff;
}

.lms-message-textbox .title {
  margin: 3px 0 3px 0;
  font-size: 16px;
  color: #24508a;
  font-weight: 500;
}

.lms-message-textbox .messagebox {
  margin: 3px 0 3px 0;
  font-size: 13px;
  background-color : #ffffff;
  font-weight: 500;
  border: 2px solid lightgray;
}

.contentsTableRow {
  height: 40px;
  margin-left: 20px;
  text-align: left;
}

.MuiGrid-item {
  padding: 10px 0;
}

.popupTitle {
  height: 17px;
  display: inline-block;
  color: #ffffff;
  font-size: 15px;
  line-height: 18px;
  margin: 10px;
  padding: 5px 7px;
  background-color: #076db6;
  border-radius: 5px;
}

.popupContents {
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  padding: 0;
  background-color: #fafafa;
  display: inline-block;
  min-height: 100px;
  height: calc(100% - 47px);
  overflow: hidden;
  text-align: center;
}

.popupContents.popupContentsPadding {
  width: calc(100% - 32px);
  padding: 16px;
  height: calc(100% - 79px);
  text-align: left;
}

.paging-item {
  margin-left: 2px;
  margin-right: 2px;
}

.tooltipTd {
  position: relative !important;
}

.receiptHistDatepicker input {
  text-align: end;
}

.receiptRequestResultDatePicker input {
  padding-left: 20px !important;
}

.editableCellInput input {
  text-align: right;
}

.certificateLogin {
  margin-top: 18px;
}
.certificateLogin label span {
  margin: 0;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ellipsisWithFixedHeight {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 43px;
}

.d-inline-block {
  display: inline-block;
}
.d-inline-flex {
  display: inline-flex;
}
.d-flex {
  display: flex;
}
.wrap {
  flex-wrap: wrap;
}
.flex-center {
  align-items: center;
}
@import url(./contents.css); // login 전후 css
@import url(./print.css); // print용 css
