@page {
  .print-outer-box {
    margin: 0 0 0 0;
  }
  .print-label-area {
    margin: 0;
  }
}
//라벨지 출력 - 출력 영역
.print-outer-box {
  text-align: left;
  display: block;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  justify-content: center;
  width: 100%;
}
.print-area {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  page-break-after: always;
  text-align: left;
  width: 21cm;
  height: 28.7cm;
  font-size: 13px;
  padding: 14mm 0 0 3mm;
}

.print-label-area {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  text-align: left;
  font-size: 13px;
}

.print-label-box-bottom-l {
  flex-direction: column;
  float: left;
  width: 55%;
}

.print-label-box-bottom-r {
  flex-direction: column;
  float: right;
  width: 45%;
}

//라벨지 출력 - 규격 16칸 선택 시
.print-label-box-16 {
  display: flex;
  // float: left;
  // flex-direction: column;
  flex-wrap: wrap;
  border: 1px dotted #aaa;
  margin: 0;
  padding: 0mm;
  width: calc(50% - 2.5mm);
  height: 35.1mm;
  font-size: 1em;
  &:nth-child(2n + 1) {
    margin-right: 3mm;
  }
}

.MuiFlatPagination-root {
  display: inline-table;
  text-align: center;
}

//라벨지 출력 - 규격 24칸 선택 시
.print-label-box-24 {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  border: 1px dotted #aaa;
  margin: 0;
  width: calc(33.33% - 2mm);
  height: 34.4mm;
  font-size: 0.7em;
  &:nth-child(3n + 1),
  &:nth-child(3n + 2) {
    margin-right: 2mm;
  }
}

//라벨지 출력 - 규격 40칸 선택 시
.print-label-box-40 {
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  border: 1px dotted #aaa;
  margin: 0;
  width: calc(25% - 3mm);
  height: 27.3mm;
  &:nth-child(4n + 1),
  &:nth-child(4n + 2),
  &:nth-child(4n + 3) {
    margin-right: 2mm;
  }
  & .print-label-box-bottom-l {
    width: 60%;
  }
  & .print-label-box-bottom-r {
    width: 40%;
  }
}

//라벨지 출력 - 박스 내 상단 영역
.print-label-box-top {
  text-align: left;
  width: 100%;
  height: 50%;
  margin: 3mm 2mm 0 2mm;
}
.print-label-box-40 .print-label-box-top {
  margin: 2mm 0 0 0;
}

//라벨지 출력 - 박스 내 하단 영역
.print-label-box-bottom {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0 2mm 3mm 2mm;
}

//라벨지 출력 - 주소
.print-label-address {
  width: fit-content;
  margin: 2% 2% 0 2%;
}

.print-label-box-24 .print-label-address {
  padding: 0 2mm;
}

.print-label-box-40 .print-label-address {
  padding: 0 2mm;
  font-size: 7px !important;
}

//라벨지 출력 - 하단 왼쪽 요소
.print-label-left {
  text-align: left;
  margin: 0;
  padding: 0 5px;
}

//라벨지 출력 - 고객명
.print-label-name {
  text-align: right;
  margin: 0;
  font-weight: bold;
}

//라벨지 출력 - 우편번호
.print-label-zipcode {
  text-align: right;
  margin: 0;
  padding: 0 5px;
}

.print-label-text-16 {
  font-size: 0.9em;
  padding: 0 3mm 0 3mm;
}

.print-label-text-24 {
  font-size: 0.7em;
  padding: 0 3mm 0 3mm;
}

.print-label-text-40 {
  font-size: 7px !important;
  padding: 0 2mm 0 2mm;
}

@media print {
  footer,
  header,
  no-print {
    display: none;
  }
  .print-outer-box {
    border: 0px solid #999;
    width: 210mm;
    height: 286mm;
    margin: 0 0 0 0;
  }
  .print-label-box-16,
  .print-label-box-24,
  .print-label-box-40 {
    border: 0px solid #999;
  }
  .print-label-area {
    page-break-before: always;
  }
}

//SMS발송 > SMS직접발송 탭 > SMS 발송 내용 작성 화면 내 문구 강조
.stronger-label {
  font-size: 15px !important;
  font-weight: 1000 !important;
}

//기부금영수증 > 인쇄 영역
.donation-print-head {
  font-size: 15px;
}

@media print and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .donationbill-print-area {
    zoom: 1.25;
  }
  .bill-print-area {
    zoom: 1.3;
  }
}

.rawForm {
  border: none;
  outline: none;
  text-align: center;
  width: 100%;
  &:active,
  &:focus,
  &:focus-visible,
  &:focus-within {
    border: none;
    outline: none;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}
