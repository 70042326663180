@media print {
  html,
  body {
    margin: 0 !important;
    padding: 0 !important;
    width: 19cm !important;
    height: 24cm !important;
  }

  #root {
    margin: 0 !important;
    padding: 0 !important;
    overflow-y: visible !important;
  }

  #root .wrap .container {
    margin: 0 !important;
    padding: 0 !important;
    width: 125%;
  }

  #header,
  .bigtitle {
    display: none;
  }
  .main {
    display: none;
  }
  .root,
  .inforbox,
  .search-area,
  .summary-area,
  .modify-area,
  .bigtitle,
  .billingone_info,
  .contentbox {
    margin: 0 !important;
    padding: 0 !important;
    width: 98% !important;
    font-size: 12px !important;
  }

  .header,
  footer,
  .select_menu,
  .nav,
  .top-line {
    display: none !important;
  }

  .MuiTableHead-root {
    display: table-row-group !important;
  }
  table,
  tr,
  td,
  .MuiTableCell-root,
  .MuiTableCell-body {
    page-break-before: always !important;
    page-break-after: always !important;
    page-break-inside: always !important;
  }

  .contentbox .half {
    width: 48%;
    float: left;
    margin: 3px;
    padding: 0;
    background-color: none;
  }

  .contentbox ul {
    width: 300px;
    display: inline-block;
    margin: 0 0 0 50px;
  }
  .bigtitle .title_main {
    font-size: 16px !important;
  }
  h4 {
    font-size: 14px !important;
  }
  h3 {
    font-size: 13px !important;
  }
  button,
  .button,
  th,
  tr,
  .tr,
  td,
  .td,
  label,
  .label,
  input,
  select,
  div,
  span,
  .bgno,
  .li-title {
    font-size: 12px !important;
  }
  .btn-s {
    font-size: 10px !important;
    padding: 1px !important;
  }
  .text-graph {
    min-width: 30px !important;
  }
  .MuiTabs-root .MuiTab-root {
    height: 30px !important;
    padding: 0 !important;
    margin: 10px 0 0 0 !important;
  }
  @page {
    margin: 2cm 1cm 2cm 1cm;
  }
}
