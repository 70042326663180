/**********이용요금**********/
@media screen {
  .print-outer-box-bill {
    width: 210mm;
    height: 277mm;
    padding: 30px 20px;
    margin: 20px 0 0 50px;
    text-align: center;
    border: 1px dotted #cccccc;
    float: left;
    display: block;
  }
}
@media print {
  .print-outer-box-bill {
    width: 210mm;
    height: 277mm;
    text-align: center;
    border: 0px;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  .inner {
    width: 206mm;
    height: 273mm;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
}
.table-top-area .print-button.btn-l {
  margin: 0 40px 0 auto;
}
.print-outer-box-bill .charge-head {
  display: inline-block;
  width: 98%;
  padding: 5px;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}
.print-outer-box-bill img {
  float: left;
  width: 50mm;
}
.print-outer-box-bill .title {
  float: left;
  font-weight: 600;
  font-size: 24px;
  width: 140mm;
  padding: 15px 0 0 10px;
}
.print-outer-box-bill .charge-summary {
  font-size: 16px;
}
.print-outer-box-bill .charge-body {
  width: 98%;
  text-align: left;
  display: inline-block;
}
.print-outer-box-bill h4 {
  font-size: 15px;
  font-weight: 600;
  margin: 30px 0 5px 0;
}
.charge01 {
  width: 100%;
  display: block;
}
.charge0101 {
  width: 100mm;
  float: left;
}
.charge0101 .th,
.charge0102 .th,
.charge0201 .th,
.charge0202 .th,
.charge0101 .td,
.charge0102 .td,
.charge0201 .td,
.charge0202 .td {
  height: 15px;
  line-height: 15px;
}
.charge0102 {
  width: 100mm;
  float: right;
}
.charge02 {
  width: 100%;
  display: inline-block;
}
.charge02 h5 {
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0 5px 0;
}
.charge0201 {
  width: 100%;
  display: inline-block;
}
.charge0202 {
  width: 100%;
  display: inline-block;
}

.print-outer-box-bill .receipt-head {
  display: inline-block;
  width: 70%;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  font-weight: 600;
  font-size: 24px;
  padding: 10px 0 10px 0;
  text-align: center;
  margin: 10px;
}
.print-outer-box-bill .receipt-body {
  width: 70%;
  text-align: left;
  display: inline-block;
}
.receipt-body .th,
.receipt-body .td {
  height: 18px;
  line-height: 22px;
}
.receipt-message {
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
  display: inline-block;
  width: 100%;
}
.receipt-date {
  font-size: 14px;
  margin-top: 30px;
  text-align: right;
  display: inline-block;
  width: 100%;
}
.receipt-number {
  font-size: 14px;
  margin-top: 30px;
  text-align: right;
  display: inline-block;
  width: 100%;
}
.receipt-bottom {
  text-align: center;
  margin-top: 50px;
  display: inline-flex;
  justify-content: center;
  width: 100%;
}
