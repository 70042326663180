/***********************************************************/
/********************** 공    통 ***************************/
/***********************************************************/
.contents-box-preview {
  border: 2px dashed brown;
}

content {
  position: relative;
  width: 1280px;
  height: 226px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.main-root {
  display: inline-block;
  margin: 0 0 20px 0;
  text-align: center;
  min-height: calc(100vh - 283px);
}

.ml_20 {
  margin-left: 20px;
}

/***********************************************************/
/********************** 로그인 전 ***************************/
/***********************************************************/
/** 로그인 전 메인 **/
.main-img {
  text-align: center;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(to bottom, #c8ecf8, #a9e0f2);
}

.main-img-area {
  justify-content: flex-end;
  height: 660px;
  width: 1420px;
  background-repeat: no-repeat;
}

.main-img-text {
  display: flex;
  flex-direction: column;
  float: right;
  text-align: right;
  margin-right: 50px;
  padding: 50px 20px 0 0;
}

.main-img-text-left {
  display: flex;
  flex-direction: column;
  float: left;
  text-align: left;
  margin-left: 50px;
  padding: 50px 0 0 40px;
}

.main-img-text img,
.main-img-text-left img {
  width: 102px;
  height: 38px;
}

.main-img-text span,
.main-img-text-left span {
  font-size: 18px;
  margin: 32px 0;
}
.main-img-text h2,
.main-img-text-left h2 {
  font-size: 31px;
  line-height: 40px;
  letter-spacing: -1px;
  font-weight: 500;
}
.main-img-text ul,
.main-img-text-left ul {
  font-size: 16px;
  font-weight: 500;
  margin-top: 320px;
}
.main-img-text li,
.main-img-text-left {
  list-style-type: none;
}
.service-icon {
  min-width: 713px;
  width: 70%;
  height: 126px;
  background: linear-gradient(to right, #ececec, #fff);
  padding: 50px 30px;
}
.service-icon .mt46 {
  margin-top: 46px;
}

.service {
  height: 38px;
  display: inline-flex;
  cursor: pointer;
}
.service h2 {
  margin: -3px 0 0px 10px;
  font-weight: 500;
}

.service span {
  margin: 0px 0 0px 10px;
  font-size: 12px;
  color: #10789b;
}
.loginhome-bottom-area {
  display: inline-flex;
  width: 100%;
}

.cs-area {
  min-width: 450px;
  width: 100%;
  height: 196px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 30px 57px 0 0;
  background: url(./images/cs-bg.png) no-repeat #c1e5e5;
}

.cs-area h2 {
  margin: 0px 0px 12px 0px;
  font-size: 32px;
  font-weight: 300;
}

.cs-area .cs-menu {
  display: inline-flex;
}

.cs-area .cs-menu a {
  text-decoration: none;
  font-size: 15px;
  color: #03253d;
  padding: 0 12px;
  border-right: 1px dashed;
  height: 18px;
  line-height: 18px;
  display: inline-block;
}

.cs-area .cs-menu a:last-child {
  border-right: none;
  padding-right: 0;
}

.cs-area .cs-menu a:hover {
  text-decoration: underline;
  color: #10789b;
}

.cs-area .cs-menu img {
  vertical-align: baseline;
  margin-bottom: 1px;
}

.cs-area .phone-num {
  margin: 0px 0 3px 0;
  color: #10789b;
  letter-spacing: -1px;
  font-weight: 400;
}

.cs-area .cs-num {
  height: 17px;
  padding: 6px 36px;
  color: #fff;
  background-color: #10789b;
  margin-bottom: 5px;
}

.cs-area span {
  margin-top: 13px;
  color: #10789b;
  font-size: 14px;
}

/** 로그인, 아이디찾기 **/
.main-root-login {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 281px);
}
.main-root-login .infobox {
  font-size: 15px;
  margin-top: 100px;
}
.main-root-login .logo-login {
  width: 450px;
  height: 40px;
  margin: 30px auto 0 auto;
  background-color: #03253d;
  padding-top: 13px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.main-root-login .login-h3 {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.main-root-login .login-area {
  width: 400px;
  height: 190px;
  background-color: rgba(16, 120, 155, 0.35);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 25px;
  margin: 0 auto 30px auto;
}

.main-root-login .id-pw {
  background-color: #fff;
  color: #555;
  width: 98%;
  font-size: 14px;
  margin: 0 0 3px 0;
  padding: 3px;
  display: block;
  border-radius: 5px;
}

.main-root-login .input-sytle {
  color: #555;
  width: 180px;
  font-size: 14px;
  padding: 0 0 0 0px;
  display: block;
}

.main-root-login .btn-login {
  background-color: rgba(16, 120, 155, 0.5);
  border: #10789b solid 1px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  width: 193px;
  height: 40px;
  margin-top: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.main-root-login .btn-findId {
  background-color: rgba(72, 77, 79, 0.5);
  border: #474f52 solid 1px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  width: 193px;
  height: 40px;
  margin-top: 20px;
  cursor: pointer;
}
.main-root-login .btn-findId.btn-triple,
.main-root-login .btn-login.btn-triple {
  width: 126px;
}
.main-root-login .label-l {
  margin: 0 30px 0 0;
}
.main-root-login .MuiInputBase-input {
  font-size: 14px;
  margin: 0;
  padding: 0;
  height: 30px;
  background-color: #fff;
}

.main-root-login .login-bottom {
  margin: 20px;
  padding-right: 120px;
}

/** footer css start **/
footer {
  position: absolute;
  width: 100%;
  height: 150px;
  background-color: #eee;
  text-align: center;
  bottom: 0;
  min-width: 1420px;
}

.ft-info-area {
  margin-top: 20px;
  display: inline-flex;
  width: 1280px;
}

.ft-info-area img.logo {
  margin-right: 30px;
}

.ft-menu_area {
  display: inline-flex;
  justify-content: space-between;
  width: 1130px;
}

.ft-menu {
  display: flex;
  flex-direction: column;
}

.ft-menu a {
  text-decoration: none;
  font-size: 14px;
  color: #757575;
  padding: 0 12px;
  border-right: 1px dotted;
  line-height: 10px;
  height: 10px;
  display: inline-block;
}

.ft-menu a:first-child {
  padding-left: 0;
}

.ft-menu a:last-child {
  border-right: none;
}

.ft-menu a:hover {
  text-decoration: underline;
  color: #10789b;
}

.ft-menu img {
  vertical-align: baseline;
  margin-bottom: 1px;
}

.ft-info-area .ft-text {
  font-size: 12px;
  color: #757575;
  margin-top: 3px;
  width: 1060px;
  text-align: left;
}

.ft-info-area .ft-text .direct-icon a {
  font-size: 12px;
  color: #757575;
  float: right;
  padding: 1px 6px;
  border: 1px solid #ddd;
  background-color: #fafafa;
  text-decoration: none;
}

.ft-menu-area {
  display: inline-flex;
  justify-content: space-between;
}

.ft-menu select {
  width: 180px;
  height: 26px;
  background-color: #e2e2e2;
  font-size: 14px;
  padding-left: 5px;
}

/** 오시는 길 **/
.map-image {
  border: 2px dotted red;
  width: 100%;
  height: 500px;
}

/** 개인정보처리방침, 고객서비스헌장 **/
.ftm {
  background-image: url(./images/billingone.png);
  padding: 40px 60px 50px 60px;
  text-align: left;
  height: 100%;
  max-height: calc(100vh - 373px);
}

.ftm2 {
  width: 100%;
  height: 100%;
  background-image: url(./images/billingone.png);
  margin-top: 30px;
  padding: 40px 10px 50px 10px;
  text-align: left;
}

.ftm p,
.ftm2 p {
  padding-left: 24px;
  padding-right: 24px;
}

.ftm .content-box-scroll-back {
  height: 100%;
  max-height: calc(100vh - 373px);
}
.ftm2 .content-box-scroll-back {
  height: 100%;
}
.ftm .content-box-scroll {
  overflow-y: auto;
  text-align: left;
  width: 100%;
  height: 100%;
  padding: 20px;
  border: 2px solid #dddddd;
  background-color: #ffffff;
  flex-wrap: wrap;
  max-height: calc(100vh - 423px);
}
.ftm2 .content-box-scroll {
  overflow-y: auto;
  text-align: left;
  width: 100%;
  height: 100%;
  max-height: 35vh;
  padding: 0px;
  border: 2px solid #dddddd;
  background-color: #ffffff;
  flex-wrap: wrap;
}
.ftm .content-box-scroll p,
.ftm2 .content-box-scroll p {
  margin: 0 10px 20px 10px;
  line-height: 24px;
  font-size: 13px;
}
.ftm .content-box-scroll ol,
.ftm2 .content-box-scroll ol {
  margin: 0 0px 50px 50px;
  line-height: 15px;
  font-size: 12px;
}
.ftm .content-box-scroll ul,
.ftm2 .content-box-scroll ul {
  margin: 0 0px 50px 50px;
  line-height: 15px;
  font-size: 12px;
  list-style-type: disc;
}
.ftm .content-box-scroll table,
.ftm2 .content-box-scroll table {
  margin: 0 0 10px 50px;
  width: 90%;
}
.ftm .content-box-scroll .box-text2,
.ftm2 .content-box-scroll .box-text2 {
  width: 90%;
  font-size: 12px;
  padding: 10px 10px 10px 20px;
  margin: 0 0 30px 20px;
  border-radius: 10px;
  color: #333;
  background-color: #eaeaea;
}
.ftm h4,
.ftm2 h4 {
  text-align: left;
  color: #03253d;
  font-size: 20px;
  font-weight: 500;
  padding-left: 23px;
  background-image: url(./images/tab-dot.png);
  background-position-y: center;
  background-repeat: no-repeat;
}

.ftm h5,
.ftm2 h5 {
  font-size: 17px;
  font-weight: 600;
  padding-left: 23px;
  margin-bottom: 20px;
  color: #10789b;
}

.ftm h6,
.ftm2 h6 {
  font-size: 14px;
  font-weight: 500;
  padding-left: 23px;
  margin-bottom: 20px;
  color: #10789b;
}

.ftm .contentbox-table th,
.ftm2 .contentbox-table th {
  background-color: #c5e2ea;
  font-weight: 500;
  color: #3e5160;
  padding: 3px;
  border-left: #bad4db solid 1px;
  border-bottom: #bad4db solid 1px;
  text-align: center;
}

.ftm .contentbox-table td,
.ftm2 .contentbox-table td {
  color: #636363;
  padding: 3px;
  border-left: #cee2e8 solid 1px;
  border-bottom: #cee2e8 solid 1px;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
}

.ftm .contentbox-table tr,
.ftm2 .contentbox-table tr {
  background-color: #dfeff3;
}

.ftm a,
.ftm2 a {
  text-decoration: none;
  color: #10789b;
}

.ftm a:hover,
.ftm2 a:hover {
  text-decoration: none;
  color: #5ac3e8;
}

/** 빌링원소개 **/
.billingone_info_top .tab-area .MuiTab-wrapper {
  font-size: 18px;
  font-weight: 500;
}
.billingone_info_top .tab-area {
  margin-bottom: 10px;
}

.billingone_info_top .tab-area .Mui-selected {
  color: #10789b;
}

.billingone_info {
  width: 1280px;
  padding: 20px 0 0 0;
  display: block;
  float: left;
}

.bgno,
.bgno li {
  background-image: none;
}

.contentbox {
  padding: 10px 0 7px 30px;
  display: block;
  font-size: 14px;
}

.tab-area {
  height: 70px;
  padding: 0;
}

.box-centered {
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin: 0 0 20px 30px;
  line-height: 18px;
  text-align: justify;
  background: url("./images/bg_back1.jpg") no-repeat;
  height: 141px;
}

.box-centered img {
  height: 10px;
  margin: 0 10px;
}

.box-centered .bg_grey_box {
  background: url("./images/bg_back2.jpg") no-repeat;
  width: 228px;
  height: 96px;
  margin-left: 25px;
  padding: 45px 0 0 114px;
  line-height: 1.2;
}

.box_group {
  display: block;
  content: "";
  clear: both;
  background: url("./images/bg_back3.jpg") no-repeat;
  width: 1047px;
  height: 141px;
  margin-top: 10px;
}

.sm_tit {
  font-size: 14px;
  color: rgba(71, 133, 192, 1);
  display: block;
  content: "";
  clear: both;
  padding-left: 20px;
}

.box_group ul {
  display: table;
  text-align: justify;
  margin: 0 !important;
}

.box_group ul li {
  width: 180px;
  height: 141px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.hidden {
  visibility: hidden;
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
}

.bg_service {
  width: 1200px;
  height: 682px;
  background: url("./images/service_img.jpg") no-repeat;
  margin-left: 20px;
  display: block;
  content: "";
  clear: both;
}

.billingone_info .logo_text {
  margin: 10px 50px 30px 50px;
  padding: 20px 20px;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #fdfff1;
  font-weight: 300;
  font-size: 15px;
  line-height: 28px;
  background-color: #076db6;
  border-radius: 5px;
}
.billingone_info .MuiBox-root {
  text-align: left;
}
.contentbox .tab1 {
  width: 93%;
  display: block;
  color: #666666;
  font-size: 16px;
  font-weight: 500;
  padding: 40px 0 7px 40px;
  line-height: 26px;
  text-align: justify;
}

.contentbox .tab4 {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0 0px 50px;
  padding: 5px 30px 0px 0px;
}

.contentbox .box-text-light-blue {
  width: 150px;
  font-size: 14px;
  color: #666;
  text-align: center;
  padding: 12px 10px;
  align-content: center;
  align-items: center;
  border-radius: 5px;
}

.contentbox .box-text-dark-blue {
  font-size: 20px;
  font-weight: 700;
  color: #278eb1;
  letter-spacing: -1px;
}

.contentbox ul {
  padding-left: 0;
  width: 100%;
  margin: 0 0 20px 50px;
}

.contentbox .box {
  width: 90%;
  padding: 10px 0 20px 50px;
  line-height: 20px;
}

.contentbox .box3 {
  width: 40%;
  padding: 0 0 20px 50px;
  line-height: 20px;
}

.contentbox .li-title1 {
  width: 23%;
  display: inline-block;
}

.contentbox .half {
  width: 48%;
  float: left;
  margin: 0 5px 15px 0;
  padding: 5px;
  background-color: #fafafa;
  display: block;
  min-height: 100px;
}

.contentbox .half .tab3 {
  width: 96%;
  height: 17px;
  display: inline-block;
  color: #ffffff;
  font-size: 15px;
  font-weight: 300;
  margin: 3px;
  padding: 5px 0 10px 20px;
  background-color: #076db6;
  border-radius: 5px;
}

.contentbox .half li.li-title {
  color: #31464d;
  list-style: none;
  font-size: 15px;
  margin: 5px 0 10px 0px;
  padding-left: 10px;
  background-image: url(./images/box-dot.png);
  background-position-y: center;
  background-repeat: no-repeat;
}

.contentbox .half ul {
  display: block;
  margin: 8px;
}

.box table,
.box3 table {
  border: 1px solid #cccccc;
}

.box th {
  background-color: #076db6;
  font-weight: 400;
  color: #ffffff;
  padding: 5px 5px;
  text-align: center;
  border-left: #ddd solid 1px;
}

.box3 th {
  background-color: #9e9e9e;
  font-weight: 300;
  font-size: 13px;
  color: #ffffff;
  padding: 3px 3px;
  text-align: center;
  border-left: #ddd solid 1px;
}

.box td {
  color: #636363;
  padding: 8px 10px;
  background-color: #ffffff;
  border-left: #ddd solid 1px;
  border-bottom: #ddd solid 1px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
}

.box3 td {
  color: #636363;
  padding: 3px 7px;
  background-color: #ffffff;
  border-left: #ddd solid 1px;
  border-bottom: #ddd solid 1px;
  text-align: center;
  vertical-align: middle;
  font-size: 13px;
}

.box tr,
.box3 tr {
  background-color: #dfeff3;
}

.atten {
  color: #ac6565;
  font-size: 13px;
  padding: 0 0 0 4px;
  border-bottom: 0px;
  width: 500px;
}

/***********************************************************/
/********************** 로그인 후 ***************************/
/***********************************************************/

/** 로그인 후 메인화면 **/
.dashboard {
  min-width: 1280px;
  width: 100%;
  height: 830px;
  display: inline-flex;
  float: left;
  font-size: 13px;
}

.dashboard .icon-header {
  display: block;
  margin: 0 0 30px 0;
}

.dashboard .no-icon-header {
  display: block;
  margin: 0 0 25px 0;
  padding: 0;
}

.content-box {
  padding: 20px 20px 10px 20px;
  border-radius: 6px;
  display: inline-block;
}
.label-area {
  display: inline-flex;
  flex-direction: row;
  margin: 3px 10px;
}

.label-area label {
  margin: 2px 7px;
  font-size: 13px;
}

.dashboard h3 {
  margin: 0 0 12px 0;
  font-size: 18px;
  font-weight: 400;
  color: #03253d;
  text-align: left;
}

.dashboard h3.icon {
  margin: 0 0 0 0;
  height: 24px;
  padding-left: 38px;
  font-size: 18px;
  font-weight: 400;
  color: #03253d;
  background-repeat: no-repeat;
  background-size: 24px;
}

.dashboard h4 {
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 0 0;
  text-align: left;
}

.dashboard .check {
  width: 300px;
  background-color: #d7e9ff;
}
.text-unit_text {
  font-size: 13px;
  font-weight: 400;
  color: #10789b;
}
.dashboard .check h4 {
  padding-bottom: 10px;
  font-weight: 500;
}

.dashboard .text-unit {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.dashboard .text-unit2 {
  padding: 0px 0px 10px 0;
  margin: 0 5px;
  font-size: 13px;
  display: inline-flex;
}

.dashboard .area-left {
  display: flex;
  flex-direction: column;
  width: 380px;
}

.dashboard .checklist {
  width: 350px;
  padding-top: 25px;
  margin-right: 30px;
}

.dashboard .limit-manag {
  width: 350px;
  padding-top: 25px;
  margin-right: 30px;
}

.dashboard .limit {
  width: 300px;
  height: 151px;
  background-color: #f7f7f7;
}

.dashboard .limit .text-unit_spnum {
  font-weight: 500;
  font-size: 13px;
}

.dashboard .notice-mate {
  width: 340px;
  padding: 25px 30px 0 8px;
}

.dashboard .notice {
  width: 305px;
  height: 145px;
  overflow: hidden;
  background-color: #f7f7f7;
  border: 1px solid #dddddd;
  padding: 15px 15px 20px 15px;
}
.dashboard .notice-mate .notice-button {
  height: 18px;
  padding: 0 5px 0 5px;
  border-radius: 3px;
  font-size: 10px;
  line-height: 18px;
  background-color: #999999;
  border: 0;
  margin: 4px 0 0 30px;
}

.notice ul li {
  width: 100%;
  list-style: none;
  display: inline-flex;
  margin-bottom: 14px;
  justify-content: space-between;
  cursor: pointer;
  color: #03253d;
  text-align: left;
  border-bottom: 1px dashed #ddd;
}

.notice ul li:hover:not(.noDataBoard) {
  color: #3da8cd;
}

.notice ul li .text {
  width: 315px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  line-height: 20px;
}

.notice ul li .date {
  color: #10789b;
  font-size: 13px;
}

.notice ul .noBorderBtm {
  border-bottom: 0px !important;
}

.notice ul .noDataBoard {
  width: 100%;
  text-align: center;
  cursor: default;
  margin-top: 70px;
  border-bottom: 0px !important;
}

.notice ul .noDataBoard div {
  width: 100%;
  text-align: center;
}
.area-right {
  width: 100%;
}

/*출금/지급 요청내역 달력 관련 css*/
.calendar-area {
  width: 1018px;
  height: 680px;
  padding: 25px 0 0 0;
  margin: 0;
  float: right;
}

.calendar {
  height: 645px;
  display: block;
  padding: 10px 30px 10px 30px;
  background-color: #e8f2ff;
}

.calendar-bottom {
  width: 600px;
  padding: 5px;
  display: block;
  text-align: left;
}

.calendar-bottom label {
  margin-right: 10px;
}
.calendar-head {
  display: inline-flex;
  width: 100%;
  height: 40px;
  vertical-align: middle;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 600;
  align-items: center;
}

.calendar-head button {
  font-size: 15px;
  color: #10789b;
}

.calendar-head button:hover {
  color: #ff6822;
}

.calendar-day-head {
  display: inline-flex;
  width: 100%;
  height: 25px;
  border: 1px solid #aaaaaa;
}

.calendar-body {
  width: 100%;
  background-color: #fafafa;
  border-left: 1px solid #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
  border-right: 1px solid #aaaaaa;
}

.calendar-week {
  display: inline-flex;
  width: 100%;
  height: 92px;
  border-right: 1px dashed #aaaaaa;
  border-bottom: 1px dashed #aaaaaa;
  border-right: 0;
  border-top: 0;
}

.calendar-week:last-child {
  border-bottom: none;
}

.day-box {
  width: 140px;
  height: 100%;
  text-align: left;
  padding: 0 0 0 0;
  border-right: 1px dashed #aaaaaa;
}

.day-box:nth-child(1) {
  color: #e84848;
  border-right: 1px dashed #aaaaaa;
}

.day-box:last-child {
  color: #3475da;
  border-right: none;
}

.holiday {
  color: #e84848;
}
span.day-label.holiday {
  padding-left: 0;
}

.day-box-sunday {
  background-color: #8a4242;
  width: 140px;
  height: 100%;
  text-align: left;
}

.day-box-saturday {
  background-color: #324f7c;
  width: 140px;
  height: 100%;
  text-align: center;
}

.day-box-week {
  background-color: #999999;
  width: 140px;
  height: 100%;
  text-align: center;
  border-right: 1px solid #aaaaaa;
}

.date-label {
  padding: 3px 0;
  text-align: center;
  color: white;
}

.day-box .day-label {
  padding: 3px 0 0 6px;
  text-align: left;
}

.day-box .other-month {
  opacity: 0.3;
}

.day-box .today {
  background-color: #10789b;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  padding: 0px;
  text-align: center;
  margin: 3px 0 0 6px;
  line-height: 20px;
}

.day-box .history-area {
  display: inline-flex;
  vertical-align: middle;
  color: black;
  margin: 4px 0 0 4px;
}

.day-box .history-area a {
  padding-left: 4px;
}

.event-type-label {
  display: inline-block;
  width: 17px;
  height: 15px;
  padding: 1px;
  color: white;
  text-align: center;
  font-size: 11px;
  border-radius: 3px;
  font-weight: 200;
  margin-right: 5px;
}

.withdraw {
  background-color: green;
}

.payment {
  background-color: blue;
}

.auto {
  background-color: rgb(30, 106, 168);
}

.history-area .history-summary {
  margin: 0 2px 0 2px;
  color: #666;
  line-height: 15px;
}

.history-area .event-cell-detail {
  position: absolute;
  text-align: left;
  width: 150px;
  height: auto;
  padding: 10px;
  background-color: #2e5281;
  color: #fff;
  border-radius: 10px;
  margin: 20px;
}

/*출금/지급 요청내역 달력 관련 css 종료*/

.cus-sup {
  width: 1018px;
  margin: 25px 0 0 0px;
  float: right;
}

.custo {
  width: 1018px;
  height: 51px;
  padding: 15px 0;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
}

.custo .icon-area {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 660px;
  padding: 20px;
}

.icon-area a {
  text-decoration-line: none;
  display: inline-flex;
  align-items: center;
  color: #489ac9;
  font-size: 15px;
  font-weight: 500;
}

.icon-area img {
  margin-right: 8px;
}

.custo .cus-center {
  width: 220px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  text-align: center;
  color: #10789b;
  border-left: 1px dashed #b8b8b8;
  margin: 0 30px 0 30px;
  padding-left: 30px;
  padding-right: 16px;
}

.cus-center-title {
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 5px 0;
  color: #fff;
  background-color: #888;
  padding: 2px 10px 2px 15px;
  border-radius: 5px;
}

.custo .cus-center .work-time {
  font-size: 12px;
  font-weight: 300;
  margin: 3px 0 0 15px;
  float: right;
  color: #eee;
}

.custo .cus-center .phone-num {
  font-size: 20px;
  font-weight: 600;
  padding-right: 17px;
}

.custo .cus-center .phone-num-short {
  font-size: 13px;
  letter-spacing: -0.5px;
  color: #999;
  font-weight: 400;
  margin: 0 0 0 5px;
}
.custo .cus-center .phone-num-short span {
  font-size: 12px;
  padding: 0 0 5px 0;
  margin: 0 3px;
  color: #aaa;
}

/* ::::::::::::::::::::::공지사항 TAB ::::::::::::::::::::::: */
.tabcontent {
  display: none;
}
ul.tab {
  margin: 0;
  padding: 0;
  width: 220px;
  height: 26px;
  display: inline-flex;
  float: left;
}
ul.tab li {
  margin: 0;
  float: left;
  font-size: 13px;
  font-weight: 400;
  background-color: #e0e0e0;
  color: #757575;
  cursor: pointer;
  list-style: none;
  text-align: center;
  padding-top: 5px;
  width: 167px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
ul.tab li.current {
  margin: 0;
  font-size: 13px;
  color: #03253d;
  cursor: default;
  background-color: #afdff1;
}

.tabcontent.current {
  display: block;
}
